import React from "react"
import { Col, Row, PageHeader } from "antd"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import SiteLayout from "../components/site-layout"
import Seo from "../components/seo"

const NotFoundPage = ({ location }) => {
  /**
   * RENDER
   */
  return (
    <SiteLayout location={location}>
      <Seo title="404: Not found" />

      <PageHeader className="content-page-header" title="404: Not Found">
        <Row gutter={16}>
          <Col span={12}>
            <p>
              Sorry. Evidently the document you were looking for has either been
              moved or not longer exists. Please use the navigational links to
              above to locate additional resources and information.
            </p>
            <p>LOST? YOU MIGHT TRY...</p>
            <ul>
              <li>
                <Link to="/">Portfolio</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </Col>
          <Col span={12}>
            <StaticImage
              src="../images/404.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="404: Not Found"
            />
          </Col>
        </Row>
      </PageHeader>
    </SiteLayout>
  )
}

export default NotFoundPage
